<template>
  <div class="container mid_margin" style="margin-top:16px auto">
    <!-- Breadcrumb End-->
    <div class="row mt-4" style="justify-content:center">
      <div id="content" class="col-sm-10 col-lg-5">
        <div class="custom-box" style="height: auto;">
          <h2 class="title">Welcome Back</h2>
          <form @submit.prevent="login">
            <div class="form-group mb-3">
              <label class="form-label" for="input-email"
                >Email / Mobile Number</label
              >
              <input
                type="phone"
                name="mobile_no"
                placeholder="Ex. email@email.com / 01xxx-xxxxx"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('mobile_no') }"
                v-model="form.mobile_no"
              />
              <has-error :form="form" field="mobile_no"></has-error>
            </div>

            <div class="form-group mb-3 ">
              <label class="form-label" for="input-password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                class="form-control"
                :class="{ 'is-invalid': form.errors.has('password') }"
                v-model="form.password"
              />
              <has-error :form="form" field="password"></has-error>
            </div>

     

            <div class="form-group text-center">
              <button
                :disabled="form.busy"
                class="btn btn-sm btn-block btn-primary"
                type="submit"
              >
                <i class="fa fa-spinner fa-spin" v-if="form.busy"></i>LOGIN
              </button>
              OR
              <router-link
                style="margin:10px 10px"
                :to="{ name: 'otp_login' }"
                class="btn btn-info btn-sm btn-block "
                type="submit"
              >
                LOGIN WITH OTP
              </router-link>
            </div>
          </form>

          <hr />
          <router-link :to="{ name: 'register' }" class="registerNew">
            Don't you have an account? <strong>Register </strong>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "vue-loading-overlay/dist/vue-loading.css";
import { Form, HasError } from "vform";
import ObjectToFD from "vue-object-to-formdata";
Vue.use(ObjectToFD);



export default {
  data() {
    return {
      form: new Form({
        mobile_no: null,
        password: null,
      }),
      pre_route: null,
      isLoading: true,
      fullPage: true,
      loginDisabled: true,
    };
  },
  methods: {
    login() {
      this.loginDisabled = true;
      this.form
        .post("/login", this.$objectToFD(this.form))
        .then((resp) => {
          this.loginDisabled = false;
          console.log("Response : ", resp.data);
          this.$store.state.user = resp.data.user;
          localStorage.setItem("user_token", resp.data.token);
          this.$toast.success("Login Successful");
          this.$store.state.user = resp.data.user;

          // Route Push start
          let pushName =
            this.$store.getters.cartContent.total > 0
              ? "Checkout"
              : "UserDashboard";
          this.$router.push({ name: pushName }).go();
        })
        .catch((error) => {
          this.loginDisabled = false;
          this.$toast.error(error.response.data.message);
        });
    },

    tokenSetter(resp) {

      localStorage.setItem("user_token", resp.data.token);
    },

    backupLogin() {
      this.form
        .post("/userToLogin", this.$objectToFD(this.form))
        .then((resp) => {
          console.log(resp.data.token);
          if (resp.data.status == "SUCCESS") {
            this.$toast.success(resp.data.message);
            localStorage.setItem("user_token", resp.data.token);
            this.$store.dispatch("user");
            if (this.$store.getters.cartContent.total > 0) {
              this.$router.push({ name: "Checkout" });
            } else {
              this.$router.push({ name: "UserDashboard" });
            }
          } else {
            this.$toast.error(
              "Your given credential didn't match with our records"
            );
          }
        });
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm);
      vm.pre_route = from.name;
    });
  },
  components: {
    "has-error": HasError,

  },
};
</script>
